import React from "react";

import CameraIcon from "../images/service-icons/camera.svg";
import RollIcon from "../images/service-icons/roll.svg";
import KitchenIcon from "../images/service-icons/kitchen.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

import TechImage from "../images/tech.jpg";

const Services = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="TECHNOLÓGIE" id="tech" />
              <h2 className=" md:text-display-xl text-display-md pt-5">
                Používame technológie na úrovni svetových štandardov
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <div className="w-full md:w-1/3">
              <ServiceItem
                icon={CameraIcon}
                title="Orbitvu Fashion Studio"
                description="Orbitvu Fashion Studio navrhnuté na zábery živých modelov, ktoré dokonale prezentujú vaše oblečenie a doplnky."
              />
            </div>

            <div className="w-full md:w-1/3">
              <ServiceItem
                icon={RollIcon}
                title="Green Screen s výbavou"
                description="Máme pre vás k dispozícii osvetlenie a zameniteľné pozadia, ktoré vám pomôžu vytvoriť dokonalé fotky."
              />
            </div>

            <div className="w-full md:w-1/3">
              <ServiceItem
                icon={KitchenIcon}
                title="Kompletné zázemie"
                description="V súčastnosti je to v rámci riešenia a bude pre vás k dispozícii v októbri 2023."
              />
            </div>
          </div>
          <div className="w-full">
            <img className="block w-full h-[600px] object-contain" src={TechImage} alt="Architecture" />
          </div>

        </div>
      </div>
    </div>
  );
};
export default Services;
