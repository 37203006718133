import React from "react";

import Instagram from '../images/social-icons/instagram.svg'
import Youtube from '../images/social-icons/youtube.svg'

const Footer = () => {
  const data = [
    {
      "name": "Instagram",
      "icon": Instagram,
      "href": "https://instagram.com/sugarpixels.sk?igshid=MzRlODBiNWFlZA=="
    },
    {
      "name": "YouTube",
      "icon": Youtube,
      "href": "https://www.youtube.com/watch?v=ygnSPwRaEqg"
    },
  ];
  return (
    <footer>
      <div className="container mx-auto">
        <div className="mt-2 md:mb-20 mb-10">
          <hr className="text-neutral-300"></hr>
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12">
          <div className="lg:col-span-6 md:pr-24">
            <h3 className=" md:text-display-lg text-display-sm font-normal pb-4">
              Nakopnite svoj projekt
            </h3>
            <a
              href="https://forms.gle/S8TsNbjosQwc4cLNA"
              className=" md:text-display-lg text-display-sm italic text-primary-600 underline">
              Napíšte nám
            </a>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 xl:pl-80 lg:pl-48">
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                <strong>Residence Company TT, s.r.o.</strong><br/>
                <small>(od: 26.02.2019)</small><br/>
                Drieňová 1/B <br/>
                Bratislava - mestská časť Ružinov, 821 01 <br/>
                <small>(od: 26.02.2019)</small><br/>
                <strong>IČO: </strong>47 683 783
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Pošlite nám email
              </p>
              <a
                className="text-display-xs  font-normal text-primary-600"
                href="mailto:info@sugarpixels.sk">
                info@sugarpixels.sk
              </a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Alebo nám zavolajte
              </p>
              <a
                className="text-display-xs  font-normal text-primary-600"
                href="tel:+421903185703">
                +421 903 185 703
              </a>
            </div>
          </div>
        </div>
        <div className="md:my-20 my-10">
          <hr className="text-neutral-300"></hr>
        </div>
        <div className="flex lg:flex-row flex-col gap-8 lg:items-center justify-between md:mb-20 mb-10">
          <div className="text-body-md font-light">
            © {new Date().getFullYear()} SugarPixels. All rights reserved.
          </div>
          <div className="flex lg:flex-row flex-col lg:items-center md:gap-6 gap-4">
            <div className="flex flex-row items-center opacity-70">
              <p className="text-body-sm font-semibold tracking-widest text-neutral-700 pr-4">
                SLEDUJTE NÁS
              </p>
              <hr className="w-16 text-neutral-700"></hr>
            </div>
            <div className="flex flex-row  items-center gap-6">
              {data.map((node) => (
                <a
                  href={node.href}
                  key={node.name}
                  target="_blank"
                  rel="noreferrer">
                  <img
                    className="h-10 w-10"
                    src={node.icon}
                    alt={node.name}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
