import React from "react";

import CtaButton from "../images/cta-button.svg";
import HeroImage from "../images/hero.jpg";

const Hero = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col xl:px-32 items-center text-center gap-6 py-20">
          <h1 className=" md:text-display-2xl text-display-lg">
            Fotoštúdio, ktoré vám pomôže zvýšiť vaše predaje
          </h1>
          <p className="col-span-8 md:text-body-xl text-body-lg font-light text-neutral-700 max-w-[800px]">
            Potrebujete profesionálne fotografie pre svoj E-shop, portfólio, sociálne siete alebo reklamu? Sme fotoštúdio, ktoré sa vyzná v e-commerce a vo fotení. Ponúkame vám kvalitné služby, moderné vybavenie a kreatívny tím.
          </p>
          <p className="col-span-8 text-display-md font-light text-neutral-700 max-w-[800px]">
            <b><h2>Otvárame 03/2024</h2></b>
          </p>
        </div>
      </div>
      <div className="relative">
        <img className="object-cover w-full aspect-[6/2]" src={HeroImage} alt="Hero" />
        <a href="https://forms.gle/S8TsNbjosQwc4cLNA">
          <img
            src={CtaButton}
            alt="Get in touch"
            className="absolute xl:left-28 lg:left-[44%] md:left-[42%] left-[35%] -top-16"
          />
        </a>
      </div>
    </div>
  );
};

export default Hero;
