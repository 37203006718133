import React from "react";

import Eyebrow from "./eyebrow";
import OrbitvuVideo from "../vid.mp4";

const About = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:pt-32 lg:pb-12 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="O nás" id="about" />
            <h2 className=" md:text-display-xl text-display-md font-normal pb-4">
              Premeníme vašu predstavu na realitu
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Chcete mať profesionálne fotky vašich produktov ale nemáte rozpočet na to aby ste si zakúpili kompletné fotovybavenie? Máme k dispozícii moderné vybavenie, šikovný team ľudí a dlhoročné skúsenosti s lifestyle-ovým aj s produktovým fotením.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              V rámci základných služieb sa dá prenajať  Green Screen so svetlami a fototechnikou či už s fotografom alebo bez a samozrejme aj samotné Orbitvu Fashion Studio, ktoré sa dá prenajať iba s fotografom, ktorého vám zabezpečíme a ktorý dokáže toto štúdio dokonale ovládať.
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <video className="aspect-square object-cover" src={OrbitvuVideo} autoPlay loop muted playsInline />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
