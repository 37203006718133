import React from "react";
import PropTypes from "prop-types";

const WorkItem = ({ image, title, description, video }) => {
  return (
    <div className="flex basis-1/2 flex-col">

      { image && <img width="800" height="800" className="bg-[#f1f1f1]" src={image} alt={title}/>}
      { video && <video autoPlay loop muted width="800" height="800"><source src={video} type="video/mp4" /></video>}
      <div className="flex flex-col self-stretch pt-6">
        <h3 className=" text-display-md pb-4">{title}</h3>
        <p className="text-body-lg font-light text-neutral-700">
          {description}
        </p>
      </div>
    </div>
  );
};

WorkItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default WorkItem;
