import React from "react";

import ServiceItem from "./serviceItem";

const Prices = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <h2 id="prices" className=" md:text-display-xl text-display-md pt-5">
                Cenník
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <div className="w-full md:w-1/3">
              <ServiceItem
                title="Prenájom ateliéru"
                description="Prvá hodina 50€, následne 30€/h bez obmedzenia na počet ľudí a čas. (Bez fotografa) Prenájom zahŕňa štandardnú výbavu foto štúdia."
              />
            </div>

            <div className="w-full md:w-1/3">
              <ServiceItem
                title="Prenajom orbitvu"
                description="Prvá hodina 100€, následne 50€/h bez obmedzenia. (S technikom na orbitvu, export všetkých fotiek)"
              />
            </div>

            <div className="w-full md:w-1/3">
              <ServiceItem
                title="Komplet služby s prípravou"
                description="Zahŕňa prípravu mood boards, inšpirácie, modelky, outfity. Cena na vyžiadanie."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Prices;
