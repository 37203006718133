import React from "react";

import Button from "./button";

const Header = () => {
  const navigation = [
    { name: "O nás", href: "#about" },
    { name: "Technológie", href: "#tech" },
    { name: "Referencie", href: "#refs" },
    { name: "Cenník", href: "#prices" },
  ];
  return (
    <header>
      <div className="container mx-auto">
        <div className="flex py-5 justify-between items-center">
          <div className="flex flex-row gap-8 items-center">
            <a href="/">
              <img className="h-8 w-auto" src="https://brand.dajanarodriguez.com/brand/logo-mark.svg" alt="Logo" />
            </a>
          </div>
          <div className="flex flex-row gap-6">
            <div className="md:flex hidden flex-row gap-4 items-center">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4">
                  {item.name}
                </a>
              ))}
            </div>
            <Button label="KONTAKT" link="https://forms.gle/S8TsNbjosQwc4cLNA" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
