import React from "react";

import WorkItem from "./workItem";
import Eyebrow from "./eyebrow";
import Button from "./button";

import Work2 from "../images/works/work-2.jpg";


const Works = () => {
  const data = [
    {
      "title": "Hodinky a šperky Eliodo",
      "description": "Značka Eliodo si vytvorila produktové fotografie hodiniek a šperkov, zábery s modelkou a makro zábery detailov."
    },
    {
      video: "https://cdn.dajanarodriguez.com/uploads/public/642/701/2f0/6427012f0d0be385784959.mp4",
      "title": "Kabelky Dajana Rodriguez",
      "description": "Dajana Rodriguez si vytvorila produktové fotografie kabeliek, zábery s modelkou a makro zábery detailov."
    },
    {
      image: "https://cdn.dajanarodriguez.com/uploads/public/642/707/2d1/thumb_45413_2000_2000_0_0_auto.png",
      "title": "",
      "description": ""
    }
  ];
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12">
          <div className="grid xl:grid-cols-12 grid-cols-1 xl:gap-8 gap-10 items-center">
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10">
              <div className="flex flex-col gap-6">
                <Eyebrow label="Referencie" id="refs" />
                <h3 className="md:text-display-xl text-display-md font-normal pb-4">
                  Menší výber  <br/> z našich prác
                </h3>
              </div>
              {data.slice(0, 1).map((node) => (
                <WorkItem
                  key={node.id}
                  image={Work2}
                  title={node.title}
                  description={node.description}
                />
              ))}
              <div className="xl:flex hidden items-start">
                <Button label="KONTAKT" link="https://forms.gle/S8TsNbjosQwc4cLNA" size="lg" />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10 xl:px-14">
              {data.slice(1, 3).map((node) => {

                if (node.video) {
                  return (<WorkItem
                    key={node.id}
                    video={node.video}
                    title={node.title}
                    description={node.description}
                  />)
                }

                return (<WorkItem
                  key={node.id}
                  image={node.image}
                  title={node.title}
                  description={node.description}
                />)
              })}
            </div>
          </div>
          <div className="xl:hidden flex items-start">
            <Button label="KONTAKT" link="https://forms.gle/S8TsNbjosQwc4cLNA" size="lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
